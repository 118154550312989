import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {DataService} from '../../service/data.service';
import {DisclaimerComponent} from '../../dialog/disclaimer/disclaimer.component';
import {BackendInput} from '../../data-type/input/BackendInput';
import {DialogMethodsService} from '../../service/dialog-methods.service';
import {PensionPlan} from '../../data-type/input/PensionPlan';
import {Gender} from '../../data-type/input/Gender';
import {PlatformLocation} from '@angular/common';
import {VideoComponent} from '../../dialog/video/video.component';
import {EinkaufComponent} from '../../dialog/einkauf/einkauf.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('pensumSlider', {static: false}) pensumSlider: ElementRef;
  datecheckerror = false;
  salarycheckerror = false;
  percentcheckerror = false;
  backendInput: BackendInput = {
    dateOfBirth: null,
    annualSalary: null,
    currentRetirementAsset: null,
    pensionPlan: null,
    gender: Gender.MALE,
    pensionAge: 65,
    insuranceCardDate: new Date().getFullYear() + '-01-01',
    projectionInterest: 2,
    projectionInterestFirstYear: 0.04,
    salaryGrowth: null,
    pensum: 100
  };
  projectionError = false;
  maxDate: object;
  minDate: object;
  startDate = new Date(1960);
  errorInformationDate: string;
  isExpanded = false;
  salaryGrowthString = '0.0';
  joiningBefore: string;
  pensionPlan: string;
  converted = false;
  french = false;
  readonly Eintrittsschwelle = 22680; // Mindestjahreslohn gemäss BVG

  // stores if 'Speichern' was already done, if true

  constructor(
    public disclaimer: MatDialog,
    public dataService: DataService,
    public dialogMethods: DialogMethodsService,
    private platformLocation: PlatformLocation
  ) {
    if ((platformLocation as any).location.pathname.toString().indexOf('/fr') >= 0) {
      this.french = true;
    }
  }

  ngOnInit(): void {

    const today = new Date();
    this.maxDate = new Date(today.getFullYear() - 18, today.getMonth() + 1, 0);
    this.minDate = new Date(today.getFullYear() - 65, today.getMonth(), 1);
    /*Check for IE or Edge*/
    this.dataService.IE = !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g);
    /*Display Message if IE or Edge*/
    const that = this;
    if (that.dataService.IE === true) {
    }
  }

  logdata() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.backendInput;
    this.errorInformationDate = 'default error';
    const dialogRef = this.disclaimer.open(DisclaimerComponent, dialogConfig);
    // only convert if dialog is accepted
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.correctPensionPlan();
        if (!(this.joiningBefore != null && this.joiningBefore === 'true'
          && this.dialogMethods.dateBefore1966(this.backendInput.dateOfBirth))) {
          this.backendInput.singlePremium = 0;
        } else {
          this.backendInput.singlePremium = this.dialogMethods.thousandSeparatorWithReturn(this.backendInput.singlePremium);
        }
        this.backendInput.annualSalary = this.dialogMethods.thousandSeparatorWithReturn(this.backendInput.annualSalary);
        this.backendInput.currentRetirementAsset = this.dialogMethods.thousandSeparatorWithReturn(this.backendInput.currentRetirementAsset);
        this.backendInput.salaryGrowth = parseFloat(this.salaryGrowthString);
        this.backendInput.projectionInterest = this.backendInput.projectionInterest / 100;
        this.backendInput.pensum = this.backendInput.pensum / 100;
        this.dataService.setData(this.backendInput);
        this.converted = true;
      }
    });
  }

  projectionInterestCorrection(e) {
    const that = this;
    that.projectionError = !that.dialogMethods.projectionInterestCorrection(e);
  }

  dateCheck(e) {
    this.datecheckerror = !this.isValid(this.backendInput.dateOfBirth);
  }

  showDialog(e) {
    this.isExpanded = !this.isExpanded;
  }

  isValid(input) {
    input = this.dialogMethods.correctDate(input);
    // separate Day, Year, Month
    const dateOfBirthArr = input.toString().split('-');
    const today = new Date();
    const age: any = this.dialogMethods.calculateAgeAtDate(this.backendInput.dateOfBirth, today);
    if (age < 18 || (age === 18 && today.getMonth() < +dateOfBirthArr[1] - 1) ||
      (age === 18 && today.getMonth() === +dateOfBirthArr[1] - 1 && today.getDate() < +dateOfBirthArr[2])) {
      this.errorInformationDate = 'minDate';
    } else if (age > 70 || (age === 70 && today.getMonth() > +dateOfBirthArr[1] - 1) ||
      (age === 70 && today.getMonth() === +dateOfBirthArr[1] - 1 && today.getDate() > +dateOfBirthArr[2])) {
      this.errorInformationDate = 'maxDate';
      return false;
    } else {
      this.errorInformationDate = '';
      return true;
    }
  }

  salaryCheck(e) {
    this.salarycheckerror = !this.isValidSalary(e.srcElement.value);
  }

  isValidSalary(input) {
    // separate Day, Year, Month
    const salary = parseInt(input.replace(/\D+/g, ''), 10);
    return salary >= this.Eintrittsschwelle;
  }

  correctPensionPlan() {
    if (this.joiningBefore === 'true') {
      if (this.pensionPlan === 'PLUS') {
        this.backendInput.pensionPlan = PensionPlan.PLUS_BEFORE;
      }
      if (this.pensionPlan === 'STANDARD') {
        this.backendInput.pensionPlan = PensionPlan.STANDARD_BEFORE;
      }
      if (this.pensionPlan === 'SURPLUS') {
        this.backendInput.pensionPlan = PensionPlan.SURPLUS_BEFORE;
      }
    }
    if (this.joiningBefore === 'false') {
      if (this.pensionPlan.indexOf('PLUS') >= 0) {
        this.backendInput.pensionPlan = PensionPlan.PLUS_AFTER;
      }
      if (this.pensionPlan.indexOf('STANDARD') >= 0) {
        this.backendInput.pensionPlan = PensionPlan.STANDARD_AFTER;
      }
      if (this.pensionPlan.indexOf('SURPLUS') >= 0) {
        this.backendInput.pensionPlan = PensionPlan.SURPLUS_AFTER;
      }
    }
  }

  openVideo() {
    const dialogConfig = new MatDialogConfig();
    this.disclaimer.open(VideoComponent, dialogConfig);
  }

}
